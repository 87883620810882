import { ArrowRight } from '/components/Icons';
import Button from '/components/Button';
import CartItem from '/components/cart/CartItem';
import CartOffers from '/components/promotions/CartOffers';
// import CartUpsell from '/components/cart/CartUpsell';
import Clickable from '/components/Clickable';
import CountryFunctionality from './CountryFunctionality';
// import DiscountBasedCartDetail from '/components/promotions/DiscountBasedCartDetail';
// import { FreeGiftsCartDetail } from './promotions/FreeGiftsCartDetail';
import FreeItemCartDetail from './promotions/FreeItemCartDetail';
import LinkCheckout from '/components/LinkCheckout';
import MoneyBackCartDetail from '/components/promotions/MoneyBackCartDetail';
import MoneyBackCartLineItem from '/components/promotions/MoneyBackCartLineItem';
import MultiProductCartDetail from '/components/promotions/MultiProductCartDetail';
import Price from './Price';
import StackingCartDetail from './promotions/StackingCartDetail';
import StackingProductModal from '/components/promotions/StackingProductModal';
import SubscriptionAddon from '/components/promotions/SubscriptionAddon';
import Typography from '/components/Typography';
import dynamic from 'next/dynamic';
import { getLoopReturnsParams } from '/services/loop-returns';
import { getProductById } from '/services/products';
import { getSubscriptionData } from '/services/static/subscription/subscriptions-ordergroove';
import { linesFromVariants } from '/utils/product';
import { observer } from 'mobx-react-lite';
import { processor } from '/utils/cart-processor';
import { useCartStore } from '/state/cart-context';
import { useDiscountCodeStore } from '/state/discount-code-context';
import { useGlobalStore } from '/state/global-context';

import {
	ADD_TO_CART_ID,
	AUTO_REMOVE,
	DISCOUNTED_LINE_PRICE,
	IS_SUBSCRIPTION,
	IS_SUB_TOGGLE,
	PACK_ID,
	SUBSCRIPTION_ID,
} from '/services/static/attribute-keys';

import { trackRemoveFromCart, trackViewMiniCart } from '/services/analytics/shinesty-analytics';
import { useEffect, useMemo, useState } from 'react';

const Drawer = dynamic(() => import('/components/Drawer'));
const DrawerBody = dynamic(() => import('/components/Drawer').then((mod) => mod.DrawerBody));
const DrawerFooter = dynamic(() => import('/components/Drawer').then((mod) => mod.DrawerFooter));
const DrawerHeader = dynamic(() => import('/components/Drawer').then((mod) => mod.DrawerHeader));

const getMatchingLineData = (attributeKey, checkValue, lines) => {
	if (!lines) {
		return { ids: [], lines: [] };
	}

	return lines.reduce(
		(memo, processedLineItem) => {
			if (processedLineItem.attributes[attributeKey] === checkValue) {
				memo.ids.push(processedLineItem.id);
				memo.lines.push(processedLineItem);
			}

			return memo;
		},
		{ ids: [], lines: [] },
	);
};

const getAutoRemoveItems = (addToCartId, lines) => {
	if (!lines) {
		return { ids: [], lines: [] };
	}

	return lines.reduce(
		(memo, processedLineItem) => {
			if (
				processedLineItem.attributes[ADD_TO_CART_ID] === addToCartId &&
				processedLineItem.attributes[AUTO_REMOVE] === 'true'
			) {
				memo.ids.push(processedLineItem.id);
				memo.lines.push(processedLineItem);
			}

			return memo;
		},
		{ ids: [], lines: [] },
	);
};

const CartPanel = observer(() => {
	const cart = useCartStore();
	const discountCodeStore = useDiscountCodeStore();
	const globalStore = useGlobalStore();
	const promotion = globalStore.getPromotionalConfig();
	const [showDiscountedSub, setShowDiscountedSub] = useState(true);
	const handleRemoveClick = async (line) => {
		let toRemove = {
			ids: [line.id],
			lines: [line],
		};

		// pack products need to remove all the pack products from the cart
		if (line.attributes && line.attributes[PACK_ID]) {
			toRemove = getMatchingLineData(
				PACK_ID,
				line.attributes[PACK_ID],
				processedCart.processedLineItems,
			);
		}

		// subscription ids need to remove all subscriptions from the cart (parent and child products)
		if (line.attributes[SUBSCRIPTION_ID]) {
			toRemove = getMatchingLineData(
				SUBSCRIPTION_ID,
				line.attributes[SUBSCRIPTION_ID],
				processedCart.processedLineItems,
			);
		}

		if (line.attributes[ADD_TO_CART_ID]) {
			const autoRemoveLines = getAutoRemoveItems(
				line.attributes[ADD_TO_CART_ID],
				processedCart.processedLineItems,
			);

			toRemove.ids = [...toRemove.ids, ...autoRemoveLines.ids];
			toRemove.lines = [...toRemove.lines, ...autoRemoveLines.lines];
		}

		await cart.removeItems({ lineIds: toRemove.ids });
		trackRemoveFromCart(toRemove.lines);
	};

	const handleToggle = (val) => {
		cart.setDrawerOpen(val);
	};

	const handleQuantityChange = async (line, quantity) => {
		const arr = [];
		for (let key in line.attributes) {
			arr.push({ key: key, value: line.attributes[key] });
		}
		await cart.updateItems([{ id: line.id, quantity, attributes: arr }]);
	};

	const processedCart = useMemo(() => {
		const processed = processor(cart, promotion);
		return processed;
	}, [cart.shopifyCart.lines]);

	useEffect(() => {
		async function fetchCart() {
			await cart.loadCart();
		}
		fetchCart();
	}, []);

	useEffect(() => {
		if (processedCart?.processedLineItems) {
			trackViewMiniCart(processedCart.processedLineItems);
		}

		const discountedSubscriptionItem = processedCart?.processedLineItems?.find(
			(item) => item?.attributes[DISCOUNTED_LINE_PRICE],
		);

		if (discountedSubscriptionItem) {
			setShowDiscountedSub(false);
		}
	}, [processedCart]);

	async function convertToSubscription(item) {
		const productId = item.merchandise.product.id.replace('gid://shopify/Product/', '');
		const sellingPlanProduct = await getProductById(productId);
		const sellingPlan = sellingPlanProduct.sellingPlans.find((plan) => plan.key == 'monthly');

		const selectedVariant = item.merchandise;
		await handleRemoveClick(item);
		if (item.attributes[IS_SUBSCRIPTION]) {
			const line = {
				variantId: selectedVariant.id,
				quantity: 1,
			};

			const lines = linesFromVariants([line]);
			const analyticsData = {
				price: sellingPlanProduct.price,
				product: {
					id: sellingPlanProduct.id,
					images: [{ src: sellingPlanProduct?.images[0]?.src }],
					productType: sellingPlanProduct.productType,
					title: sellingPlanProduct.titles[0],
					vendor: sellingPlanProduct.brand || 'Shinesty',
				},
				quantity: 1,
			};
			await cart.addItems({
				analytics: [analyticsData],
				error: () => {},
				lines: lines,
			});
			return;
		}
		const subscriptionData = getSubscriptionData({
			additionalAttributes: { key: IS_SUB_TOGGLE, value: 'true' },
			discountCodeStore: discountCodeStore,
			selections: [
				{
					image: sellingPlanProduct.images[0].src,
					productType: sellingPlanProduct.productType,
					product_id: sellingPlanProduct.id,
					size: selectedVariant.title,
					title: sellingPlanProduct.title?.split(' | ')[0],
					variantId: selectedVariant.id,
					sellingPlan: sellingPlan,
					variantSku: selectedVariant.sku,
				},
			],
			sellingPlanId: sellingPlan.id,
			frequencyKey: sellingPlan.frequency,
			subscriptionType: 'single',
			discountedSubPrice: sellingPlan.recurringPrice,
		});
		await cart.addItems({
			analytics: subscriptionData.analytics,
			error: () => {},
			lines: subscriptionData.lineItems,
		});
	}

	const showFreeGiftValue =
		promotion &&
		promotion.promotionType === 'stacking-gifts' &&
		processedCart.promotionalTotal !== 0;

	let isSubscriptionCart;
	let hasHoodie = false;

	processedCart.processedLineItems.forEach((lineItem) => {
		if (lineItem.attributes[IS_SUBSCRIPTION] === 'true') {
			isSubscriptionCart = true;
		}
		if (lineItem.merchandise.product.id === 'gid://shopify/Product/6599669678149') {
			hasHoodie = true;
		}
	});

	// Fetch the data stored in local storage regarding loop exchanges.
	const loopData = getLoopReturnsParams();
	const freeShippingThreshold = cart.freeShippingVariant === 'threshold-100' ? 100 : 125;
	const freeShippingActive = cart.freeShippingVariant !== 'control';
	return (
		<Drawer isOpen={cart.drawerOpen} toggle={handleToggle} position="right">
			<DrawerHeader>
				<div className="flex items-stretch align-middle justify-between">
					<Button
						variant="naked"
						onClick={() => cart.setDrawerOpen(false)}
						title="close cart panel">
						<ArrowRight />
					</Button>
					{processedCart?.hasItems && promotion && (
						<Button variant="naked" onClick={() => cart.emptyCart()} className="uppercase">
							clear cart
						</Button>
					)}
				</div>
			</DrawerHeader>
			<DrawerBody>
				<div className="flex flex-col">
					{isSubscriptionCart && !promotion && (
						<div className="bg-v2-brown-darker text-white text-center py-2">
							<Typography variant="preheading" className="uppercase font-bold">
								<span className="text-tertiary">Free Shipping</span> With Your Subscription
							</Typography>
						</div>
					)}
					<StackingCartDetail processedCart={processedCart} />
					<MoneyBackCartDetail processedCart={processedCart} />
					{/* <DiscountBasedCartDetail
						processedCart={processedCart}
						subscriptionInCart={isSubscriptionCart}
					/> */}

					<MultiProductCartDetail processedCart={processedCart} />

					{(!processedCart?.shopifyCart?.id || !processedCart?.hasItems) && (
						<Typography className="m-4 text-center text-primary" component="div" variant="body">
							Your cart is empty for some stupid reason
						</Typography>
					)}
					<FreeItemCartDetail processedCart={processedCart} cart={cart} />
					<MoneyBackCartLineItem processedCart={processedCart} />
					{!hasHoodie && <CartOffers />}
					{processedCart?.shopifyCart?.id &&
						processedCart?.hasItems &&
						processedCart?.processedLineItems?.map((item, i) => {
							return (
								<div key={i}>
									<CartItem
										convertToSubscription={() => convertToSubscription(item)}
										item={item}
										removeCallback={() => handleRemoveClick(item, processedCart)}
										quantityCallback={(qty) => handleQuantityChange(item, qty)}
									/>
								</div>
							);
						})}
					{showDiscountedSub && (
						<CountryFunctionality functionalityKey="subscription">
							<SubscriptionAddon processedCart={processedCart} cart={cart} />
						</CountryFunctionality>
					)}

					{/* <FreeGiftsCartDetail processedCart={processedCart} cart={cart} variant="grid" /> */}
				</div>
			</DrawerBody>
			<DrawerFooter>
				<>
					{/* <CartUpsell cart={cart} processedCart={processedCart} /> */}
					<div className="w-full rounded-t-lg flex px-2 flex-col">
						<div>
							{processedCart.compareAtPrice > processedCart.subTotal && (
								<Typography
									className="text-center w-full text-[#08926B]"
									component="div"
									variant="micro">
									You&apos;re Saving{' '}
									<span className="font-semibold tracking-wider">
										{Math.floor((1 - processedCart.subTotal / processedCart.compareAtPrice) * 100)}%
									</span>
								</Typography>
							)}
							<Typography className="text-center w-full pt-2 pt-0" component="div" variant="body">
								Subtotal:{' '}
								<span className="font-semibold tracking-wider">
									<Price format={'explicit'} price={processedCart.subTotal} />
								</span>
								{processedCart.subTotal !== 0 &&
									processedCart.subTotal > freeShippingThreshold &&
									freeShippingActive && <span> + Free Shipping</span>}
							</Typography>
							{showFreeGiftValue && (
								<Typography
									className="text-[#014421] text-center w-full pb-2"
									component="div"
									variant="micro">
									Free Gifts Value: <Price price={processedCart.promotionalTotal} />
								</Typography>
							)}
						</div>

						{loopData && (
							<div className="flex items-center justify-center p-2 flex-col">
								<Typography component="p">
									You have <Price price={loopData.loop_base / 100} /> in store credit.
								</Typography>
								<Clickable
									className="text-primary"
									linkToExternal={`https://${loopData.loop_redirect_url}`}>
									Back to Return
								</Clickable>
							</div>
						)}
						{processedCart.subTotal != 0 && (
							<LinkCheckout cart={cart} processedCart={processedCart} />
						)}
					</div>
				</>
				<StackingProductModal />
			</DrawerFooter>
		</Drawer>
	);
});

export default CartPanel;
