import Countdown from '/components/Countdown';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import { getMultiProductDetails } from '/services/static/promotions/multi-product';
import { pluralize } from '/utils/pluralize';
import { useGlobalStore } from '/state/global-context';
import { useEffect, useState } from 'react';

const MultiProductCartDetail = ({ processedCart }) => {
	const [details, setDetails] = useState();
	const globalStore = useGlobalStore();
	const currentPromotion = globalStore.getPromotionalConfig();

	useEffect(() => {
		const multiProduct = getMultiProductDetails(processedCart.processedLineItems, currentPromotion);
		setDetails(multiProduct);
	}, [processedCart.processedLineItems]);

	if (!currentPromotion || currentPromotion.promotionType !== 'multi-product' || !details) {
		return null;
	}

	let message = '';

	if (details.emptyCart) {
		message = currentPromotion.sitewideBanner.text;
	} else if (details.nextFree) {
		message = 'FREE PAIRS UNLOCKED: Add Free Socks or Underwear Now';
	} else if (details.quantityNeeded > 0) {
		const nextFreeCount = currentPromotion.quantityFree;
		message = `Add ${details.quantityNeeded} More ${pluralize(
			'Pair',
			details.quantityNeeded,
		)} to Get ${nextFreeCount} Free`;
	}
	return (
		<div
			className="bg-gray-600 text-center p-2"
			style={{
				backgroundColor: currentPromotion.sitewideBanner.colorPrimary,
				color: currentPromotion.sitewideBanner.colorSecondary,
			}}>
			<Typography component="div">{message}</Typography>
			<div className="text-white">
				Sale ends in <Countdown countdownDate={currentPromotion.dateEnd} />
			</div>
		</div>
	);
};

MultiProductCartDetail.propTypes = {
	processedCart: PropTypes.object,
};

export default MultiProductCartDetail;
